import {
	Alert,
	AlertIcon,
	Box,
	Button,
	Flex,
	FormErrorMessage,
	Input,
	Radio,
	RadioGroup,
	Select,
	Stack,
	VStack
} from '@chakra-ui/react'
import {useAuth} from 'contexts/AuthContext'
import {graphql, Link, navigate} from 'gatsby'
import React, {useState, useCallback} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {CtaiconsBlock, FormElement, FormSection} from 'starterComponents'
import {Container} from 'starterUiComponents'
import ApiFetch from '../../utils/api'
import {countryNames, statesPerCountry} from '../../utils/country_states'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'

const BRAINTREE_TOKENIZATION_KEY = process.env.GATSBY_BRAINTREE_TOKENIZATION_KEY

const WBoxes = [
	{
		value: 'yes',
		title: 'Please put me in the Warranty Shop Program!',
		subtitle: 'This is free option with your membership.'
	},
	{value: 'no', title: 'DO NOT make me an ATSG Warranty Shop'}
]

let dropin = null

if (typeof window !== `undefined`) {
	dropin = require('braintree-web-drop-in')
}

let dropinInstance = null
const SignupPage = (props) => {
	const {handleSubmit, register, watch, control, errors} = useForm({
		defaultValues: {
			address_1: '',
			address_2: '',
			address_3: '',
			city: '',
			firstName: '',
			lastName: '',
			country: 'United States',
			state: '',
			email: '',
			password: '',
			phone: '',
			shop_name: '',
			warrantyProgram: 'yes',
			bulletins: 'no',
			hearedFromUs: undefined,
			zip: ''
		}
	})

	const {
		data: {
			page: {
				flexLayouts: {flexibleLayouts}
			}
		}
	} = props

	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)
	const {login} = useAuth()
	const {executeRecaptcha} = useGoogleReCaptcha()

	const bulletinsWatch = watch('bulletins')
	const countryWatch = watch('country')

	const dropinRef = React.useRef(null)

	const handleReCaptchaVerify = useCallback(
		async (event) => {
			event.preventDefault()
			if (!executeRecaptcha) {
				console.log('Execute recaptcha not yet available')
				setError(`Unexpected error, please retry later`)
				return
			}
			try {
				const token = await executeRecaptcha('signup')
				return token
			} catch (error) {
				console.log('error: ', error)
				setError(`Unexpected error, please retry later`)
				return
			}
		},
		[executeRecaptcha]
	)

	const onSubmit = async (data, e) => {
		const recaptchaToken = await handleReCaptchaVerify(e)
		if (!recaptchaToken) {
			setError(`Unexpected error, please retry later`)
			return
		}
		const {email, password} = data
		data.grandTotal = data.bulletins === 'no' ? 75 : 75 + 8.5
		data.contactName = `${data.firstName} ${data.lastName}`
		data.recaptchaToken = recaptchaToken

		try {
			setError('')
			setLoading(true)
			const payload = await dropinInstance.requestPaymentMethod()
			data.paymentMethodNonce = payload.nonce
			data.bulletins = data.bulletins === 'yes' ? true : false
			const response = await ApiFetch({
				method: 'POST',
				path: `/companies`,
				body: data
			})
			// The email address is already in use by another account
			if (response.error) {
				if (response.code === 'auth/email-already-exists') {
					// setError(`The email address is already in use by another account`)
					setError(`auth/email-already-exists`)
				} else {
					setError(`Failed to create an account: ${response.error}`)
				}
				alertRef?.current && alertRef.current.scrollIntoView()
			} else {
				await login(email, password)
				navigate('/dashboard')
			}
			setLoading(false)
		} catch (e) {
			console.error('Failed to create an account: ', e.message)
			setError(`Failed to create an account: ${e.message}`)
		}
		setLoading(false)
	}

	const onErrors = (errors) => console.error('errors', errors)

	const alertRef = React.useRef()

	const createDropinInstance = React.useCallback(() => {
		dropin
			.create({
				authorization: BRAINTREE_TOKENIZATION_KEY,
				container: '#dropin-container'
			})
			.then((res) => {
				dropinInstance = res
			})
			.catch((err) => {
				console.log('ERROR: ', err)
			})
	}, [])

	// React.useEffect(() => {
	// 	if (dropin && dropinRef.current.children.length < 1) {
	// 		createDropinInstance()
	// 	}
	// }, [createDropinInstance])

	const parseError = (error) => {
		if (error === 'auth/email-already-exists') {
			error = 'The email address is already in use by another account'
			return (
				<span>
          <span style={{color: 'white'}}>{error}</span>
          <Link
			  to="/sign-in"
			  className="relative text-base uppercase font-heading text-white ml-2"
		  >
            . GO TO LOGIN?
          </Link>
        </span>
			)
		}
		return <span style={{color: 'white'}}>{error}</span>
	}
	return (
		<>
			{flexibleLayouts?.map((block, index) => {
				switch (block.__typename) {
					case 'WpPage_Flexlayouts_FlexibleLayouts_CtaiconsBlock':
						return <CtaiconsBlock {...block} key={index}/>

					default:
						return ''
				}
			})}
			<Container>
				<h2>Our team is ready to help you get started! </h2>
				<span>Give us a call at (800) 245-7722 and we'll get everything set up.</span>
			</Container>
			{/*<Container>*/}
			{/*	{error && (*/}
			{/*		<Alert ref={alertRef} status="error" style={{marginTop: '1em'}}>*/}
			{/*			<AlertIcon/>*/}
			{/*			{parseError(error)}*/}
			{/*		</Alert>*/}
			{/*	)}*/}

			{/*	<form onSubmit={handleSubmit(onSubmit, onErrors)}>*/}
			{/*		<FormSection*/}
			{/*			bgColor="lightBlue"*/}
			{/*			title="Advantage Warranty Shop Program"*/}
			{/*			mt={11}*/}
			{/*			plSmall*/}
			{/*		>*/}
			{/*			<Stack*/}
			{/*				direction={{base: 'column', xl: 'row'}}*/}
			{/*				className="text-center "*/}
			{/*			>*/}
			{/*				<Flex className="justify-center w-full xl:justify-start">*/}
			{/*					<VStack spacing={7} w="250px" className="mb-5 xl:mb-0">*/}
			{/*						<Box className="font-bold font-heading text-l">*/}
			{/*							Please choose one:*/}
			{/*						</Box>*/}
			{/*						<Box className="text-sm">*/}
			{/*							For more information about becoming a ATSG Advantage*/}
			{/*							Warranty Program Shop please visit the link below.*/}
			{/*						</Box>*/}
			{/*						<Link*/}
			{/*							to="/advantage-warranty"*/}
			{/*							className="text-sm font-bold uppercase text-lightBlue hover:text-darkBlue"*/}
			{/*						>*/}
			{/*							Warranty Program Details*/}
			{/*						</Link>*/}
			{/*					</VStack>*/}
			{/*				</Flex>*/}

			{/*				<Controller*/}
			{/*					control={control}*/}
			{/*					name="warrantyProgram"*/}
			{/*					as={*/}
			{/*						<RadioGroup*/}
			{/*							defaultValue="yes"*/}
			{/*							ref={register}*/}
			{/*							name="warrantyProgram"*/}
			{/*						>*/}
			{/*							<Stack*/}
			{/*								direction={{base: 'column', lg: 'row'}}*/}
			{/*								spacing={7}*/}
			{/*							>*/}
			{/*								{WBoxes.map((box, i) => {*/}
			{/*									return (*/}
			{/*										<Box*/}
			{/*											key={i}*/}
			{/*											minW={{base: '100%', sm: '350px'}}*/}
			{/*											className="p-6 bg-white rounded-xl border-current"*/}
			{/*										>*/}
			{/*											<Radio value={box.value} flexDirection="column">*/}
			{/*												<Box>*/}
			{/*													<Box*/}
			{/*														className="font-bold uppercase font-heading text-l2">*/}
			{/*														{box.value}*/}
			{/*													</Box>*/}
			{/*													<Box*/}
			{/*														className="font-bold leading-tight font-heading text-l">*/}
			{/*														{box.title}*/}
			{/*													</Box>*/}
			{/*													{box.subtitle && (*/}
			{/*														<Box mt={8}>{box.subtitle}</Box>*/}
			{/*													)}*/}
			{/*												</Box>*/}
			{/*											</Radio>*/}
			{/*										</Box>*/}
			{/*									)*/}
			{/*								})}*/}
			{/*							</Stack>*/}
			{/*						</RadioGroup>*/}
			{/*					}*/}
			{/*				></Controller>*/}
			{/*			</Stack>*/}
			{/*		</FormSection>*/}

			{/*		/!* Account Information *!/*/}

			{/*		<FormSection bgColor="lightBlue" title="Account Information">*/}
			{/*			<FormElement*/}
			{/*				id="firstName"*/}
			{/*				label="First Name:"*/}
			{/*				type="input"*/}
			{/*				required*/}
			{/*				isInvalid={Boolean(errors.firstName)}*/}
			{/*			>*/}
			{/*				<Input*/}
			{/*					placeholder="Your first name"*/}
			{/*					ref={register({required: true})}*/}
			{/*					name="firstName"*/}
			{/*					errorBorderColor="red"*/}
			{/*				/>*/}
			{/*				<FormErrorMessage style={{color: 'red'}}>*/}
			{/*					{errors?.firstName?.message ||*/}
			{/*						`firstName is ${errors?.firstName?.type}`}*/}
			{/*				</FormErrorMessage>*/}
			{/*			</FormElement>*/}
			{/*			<FormElement*/}
			{/*				id="lastName"*/}
			{/*				label="Last Name:"*/}
			{/*				type="input"*/}
			{/*				required*/}
			{/*				isInvalid={Boolean(errors.lastName)}*/}
			{/*			>*/}
			{/*				<Input*/}
			{/*					placeholder="Your last name"*/}
			{/*					ref={register({required: true})}*/}
			{/*					name="lastName"*/}
			{/*					errorBorderColor="red"*/}
			{/*				/>*/}
			{/*				<FormErrorMessage style={{color: 'red'}}>*/}
			{/*					{errors?.lastName?.message ||*/}
			{/*						`lastName is ${errors?.lastName?.type}`}*/}
			{/*				</FormErrorMessage>*/}
			{/*			</FormElement>*/}

			{/*			<FormElement*/}
			{/*				id="password"*/}
			{/*				label="Choose a Password:"*/}
			{/*				type="input"*/}
			{/*				required*/}
			{/*				isInvalid={Boolean(errors.password)}*/}
			{/*			>*/}
			{/*				<Input*/}
			{/*					type="password"*/}
			{/*					placeholder="password"*/}
			{/*					ref={register({required: true})}*/}
			{/*					name="password"*/}
			{/*				/>*/}
			{/*				<FormErrorMessage style={{color: 'red'}}>*/}
			{/*					{errors?.password?.message ||*/}
			{/*						`password is ${errors?.password?.type}`}*/}
			{/*				</FormErrorMessage>*/}
			{/*			</FormElement>*/}
			{/*			<FormElement*/}
			{/*				id="shop"*/}
			{/*				label="Shop Name:"*/}
			{/*				type="input"*/}
			{/*				isInvalid={Boolean(errors.password)}*/}
			{/*			>*/}
			{/*				<Input placeholder="shop name" name="shop_name" ref={register}/>*/}
			{/*				<FormErrorMessage style={{color: 'red'}}>*/}
			{/*					{errors?.shop_name?.message ||*/}
			{/*						`Shop Name is ${errors?.shop_name?.type}`}*/}
			{/*				</FormErrorMessage>*/}
			{/*			</FormElement>*/}
			{/*			<FormElement*/}
			{/*				id="address1"*/}
			{/*				label="Address 1:"*/}
			{/*				required*/}
			{/*				sx={{label: {mt: 3}}}*/}
			{/*				isInvalid={Boolean(errors.address_1)}*/}
			{/*			>*/}
			{/*				<Input*/}
			{/*					placeholder="Line address 1"*/}
			{/*					name="address_1"*/}
			{/*					ref={register({required: true})}*/}
			{/*				/>*/}
			{/*				<FormErrorMessage style={{color: 'red'}}>*/}
			{/*					{errors?.address_1?.message ||*/}
			{/*						`Address is ${errors?.address_1?.type}`}*/}
			{/*				</FormErrorMessage>*/}
			{/*			</FormElement>*/}
			{/*			<FormElement*/}
			{/*				id="address2"*/}
			{/*				label="Address 2:"*/}
			{/*				sx={{label: {mt: 3}}}*/}
			{/*			>*/}
			{/*				<Input*/}
			{/*					placeholder="Line address 2"*/}
			{/*					name="address_2"*/}
			{/*					ref={register}*/}
			{/*				/>*/}
			{/*			</FormElement>*/}
			{/*			<FormElement*/}
			{/*				id="address3"*/}
			{/*				label="Address 3:"*/}
			{/*				sx={{label: {mt: 3}}}*/}
			{/*			>*/}
			{/*				<Input*/}
			{/*					placeholder="Line address 3"*/}
			{/*					name="address_3"*/}
			{/*					ref={register}*/}
			{/*				/>*/}
			{/*			</FormElement>*/}
			{/*			<FormElement id="city" label="City:" type="input" required isInvalid={Boolean(errors.city)}>*/}
			{/*				<Input*/}
			{/*					placeholder="City"*/}
			{/*					ref={register({required: true})}*/}
			{/*					name="city"*/}
			{/*				/>*/}
			{/*				<FormErrorMessage style={{color: 'red'}}>*/}
			{/*					{errors?.city?.message || `city is ${errors?.city?.type}`}*/}
			{/*				</FormErrorMessage>*/}
			{/*			</FormElement>*/}
			{/*			<FormElement*/}
			{/*				id="country"*/}
			{/*				label="Country:"*/}
			{/*				type="input"*/}
			{/*				required*/}
			{/*				isInvalid={Boolean(errors.country)}*/}
			{/*			>*/}
			{/*				<Select*/}
			{/*					placeholder="Country"*/}
			{/*					ref={register({required: true})}*/}
			{/*					name="country"*/}
			{/*					isInvalid={Boolean(errors.country)}*/}
			{/*				>*/}
			{/*					{countryNames.map((country) => (*/}
			{/*						<option value={country} key={country}>*/}
			{/*							{country}*/}
			{/*						</option>*/}
			{/*					))}*/}
			{/*				</Select>*/}
			{/*				<FormErrorMessage style={{color: 'red'}}>*/}
			{/*					{errors?.country?.message ||*/}
			{/*						`country is ${errors?.country?.type}`}*/}
			{/*				</FormErrorMessage>*/}
			{/*			</FormElement>*/}
			{/*			<FormElement*/}
			{/*				id="state"*/}
			{/*				label="State:"*/}
			{/*				type="input"*/}
			{/*				required*/}
			{/*				isInvalid={Boolean(errors.state)}*/}
			{/*			>*/}
			{/*				<Select*/}
			{/*					placeholder="State"*/}
			{/*					ref={register({required: true})}*/}
			{/*					name="state"*/}
			{/*					defaultValue={''}*/}
			{/*				>*/}
			{/*					{statesPerCountry[countryWatch] &&*/}
			{/*						statesPerCountry[countryWatch].map((state) => (*/}
			{/*							// I mixed label/values on the json :D*/}
			{/*							<option value={state.label} key={state.label}>*/}
			{/*								{state.value}*/}
			{/*							</option>*/}
			{/*						))}*/}
			{/*				</Select>*/}
			{/*				<FormErrorMessage style={{color: 'red'}}>*/}
			{/*					{errors?.state?.message || `state is ${errors?.state?.type}`}*/}
			{/*				</FormErrorMessage>*/}
			{/*			</FormElement>*/}
			{/*			<FormElement*/}
			{/*				id="postal-code"*/}
			{/*				label="Zip or Postal Code:"*/}
			{/*				type="input"*/}
			{/*				required*/}
			{/*				isInvalid={Boolean(errors.zip)}*/}
			{/*			>*/}
			{/*				<Input*/}
			{/*					placeholder="Zip or Postal Code"*/}
			{/*					ref={register({required: true})}*/}
			{/*					name="zip"*/}
			{/*				/>*/}
			{/*				<FormErrorMessage style={{color: 'red'}}>*/}
			{/*					{errors?.zip?.message || `zip code is ${errors?.zip?.type}`}*/}
			{/*				</FormErrorMessage>*/}
			{/*			</FormElement>*/}
			{/*			<FormElement*/}
			{/*				id="phone"*/}
			{/*				label="Phone:"*/}
			{/*				type="input"*/}
			{/*				required*/}
			{/*				isInvalid={Boolean(errors.phone)}*/}
			{/*			>*/}
			{/*				<Input*/}
			{/*					type="tel"*/}
			{/*					placeholder="+X (XXX) XXX-XXXX"*/}
			{/*					ref={register({required: true})}*/}
			{/*					name="phone"*/}
			{/*				/>*/}
			{/*				<FormErrorMessage style={{color: 'red'}}>*/}
			{/*					{errors?.phone?.message || `phone is ${errors?.phone?.type}`}*/}
			{/*				</FormErrorMessage>*/}
			{/*			</FormElement>*/}
			{/*			<FormElement*/}
			{/*				id="email"*/}
			{/*				label="Your Email:"*/}
			{/*				type="input"*/}
			{/*				required*/}
			{/*				isInvalid={Boolean(errors.email)}*/}
			{/*			>*/}
			{/*				<Input*/}
			{/*					placeholder="your email"*/}
			{/*					type="email"*/}
			{/*					ref={register({required: true})}*/}
			{/*					name="email"*/}
			{/*				/>*/}
			{/*				<FormErrorMessage style={{color: 'red'}}>*/}
			{/*					{errors?.email?.message || `email is ${errors?.email?.type}`}*/}
			{/*				</FormErrorMessage>*/}
			{/*			</FormElement>*/}
			{/*			<FormElement*/}
			{/*				id="where-did-you-hear"*/}
			{/*				label="How did you hear about us?"*/}
			{/*			>*/}
			{/*				<Controller*/}
			{/*					control={control}*/}
			{/*					name="hearedFromUs"*/}
			{/*					as={*/}
			{/*						<RadioGroup ref={register} name="hearedFromUs">*/}
			{/*							<VStack align="flex-start" spacing={4}>*/}
			{/*								<Radio value="facebook">Facebook </Radio>*/}
			{/*								<Radio value="youtube">YouTube</Radio>*/}
			{/*								<Radio value="google">Google</Radio>å*/}
			{/*								<Radio value="referral">Referral</Radio>*/}
			{/*								<Radio value="other">Other</Radio>*/}
			{/*								/!* <Radio value="other">*/}
			{/*          <Input placeholder="other" height={10} ref={register} name="hearedFromUsOther" />*/}
			{/*        </Radio> *!/*/}
			{/*							</VStack>*/}
			{/*						</RadioGroup>*/}
			{/*					}*/}
			{/*				></Controller>*/}
			{/*			</FormElement>*/}
			{/*		</FormSection>*/}

			{/*		/!* Payment info*!/*/}

			{/*		<FormSection bgColor="lightBlue" title="Payment Information">*/}
			{/*			<FormElement label="Printed Bulletins Option:">*/}
			{/*				<Controller*/}
			{/*					control={control}*/}
			{/*					name="bulletins"*/}
			{/*					as={*/}
			{/*						<RadioGroup defaultValue="no" ref={register} name="bulletins">*/}
			{/*							<VStack align="flex-start" spacing={4}>*/}
			{/*								<Radio value="no">*/}
			{/*									No thanks, I will view my Bulletins online in the ATSG*/}
			{/*									Members Area.*/}
			{/*								</Radio>*/}
			{/*								<Radio value="yes">*/}
			{/*									<div>*/}
			{/*										Yes, I would like my Bulletins Delivered to me on*/}
			{/*										Paper. This incluces the current year bulletins as*/}
			{/*										well as future mailings.{' '}*/}
			{/*									</div>*/}
			{/*									<strong> +$8.50 Per Month Additional Charge</strong>*/}
			{/*								</Radio>*/}
			{/*							</VStack>*/}
			{/*						</RadioGroup>*/}
			{/*					}*/}
			{/*				></Controller>*/}
			{/*			</FormElement>*/}
			{/*			<div id="dropin-container" ref={dropinRef}></div>*/}
			{/*			<Flex className="justify-end w-full">*/}
			{/*				<VStack>*/}
			{/*					<Box className="font-bold font-heading text-l">*/}
			{/*						Grand Total:{' '}*/}
			{/*						<span*/}
			{/*							style={{minWidth: '180px'}}*/}
			{/*							className="inline-block text-right"*/}
			{/*						>*/}
			{/*        ${bulletinsWatch === 'no' ? 75 : 75 + 8.5}/month*/}
			{/*      </span>*/}
			{/*					</Box>*/}
			{/*				</VStack>*/}
			{/*			</Flex>*/}
			{/*			<Flex className="justify-start w-full">*/}
			{/*				<VStack>*/}
			{/*					<Box*/}
			{/*						className="mt-4"*/}
			{/*						style={{*/}
			{/*							fontSize: '0.8rem',*/}
			{/*							lineHeight: '1rem'*/}
			{/*						}}*/}
			{/*					>*/}
			{/*      <span>*/}
			{/*        This site is protected by reCAPTCHA and the Google{' '}*/}
			{/*		  <a href="https://policies.google.com/privacy">*/}
			{/*          Privacy Policy*/}
			{/*        </a>{' '}*/}
			{/*		  and{' '}*/}
			{/*		  <a href="https://policies.google.com/terms">*/}
			{/*          Terms of Service*/}
			{/*        </a>{' '}*/}
			{/*		  apply.*/}
			{/*      </span>*/}
			{/*					</Box>*/}
			{/*				</VStack>*/}
			{/*			</Flex>*/}
			{/*		</FormSection>*/}
			{/*		<Flex justify={{base: 'center', sm: 'flex-end'}}>*/}
			{/*			<Stack*/}
			{/*				direction={{base: 'column', sm: 'row'}}*/}
			{/*				spacing={5}*/}
			{/*				sx={{button: {width: 200}}}*/}
			{/*			>*/}
			{/*				<Button variant="outlineDark">Reset</Button>*/}
			{/*				<Button*/}
			{/*					variant="lightBlue"*/}
			{/*					onClick={handleSubmit(onSubmit)}*/}
			{/*					disabled={loading}*/}
			{/*				>*/}
			{/*					Submit{loading && 'ting'}*/}
			{/*				</Button>*/}
			{/*			</Stack>*/}
			{/*		</Flex>*/}
			{/*	</form>*/}
			{/*</Container>*/}
		</>
	)
}

export default SignupPage

export const signUpQuery = graphql`
  query WpPageSignUpQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      flexLayouts {
        flexibleLayouts {
          __typename
          ...ctaiconsBlockFragment
        }
      }
    }
  }
`
